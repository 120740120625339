/** @format */

import React from 'react';
import {Box, Container} from '@chakra-ui/layout';
import Layout from '../components/layout';

import getFooter from '../lib/api/acf/footer';
import mappingFooter from '../patterns/Footer/mappingFooter';

import mappingNavBar from '../patterns/NavBar/mappingNavBar';
import RichTextCta from '../patterns/RichTextCta';
import {useRouter} from 'next/router';
import HeroBanner from '../patterns/HeroBanner';
import {
	getMainMenu,
	getGlobalMainMenu,
	getParentSiteMainMenu,
	getEventsMenu,
} from '../lib/api/acf/navBarMenu';

function Custom404({post, seo, navBar, footer, subdomain}) {
	// console.log('The 404 page. ');
	const router = useRouter();
	const eventPath = router.asPath.includes('events') ? 'events' : null;
	//
	const subdomainColorScheme = (
		subdomain: string,
	): {
		subdomainBackground: string;
		subdomainColourName: string;
	} => {
		if (eventPath) {
			return {
				subdomainBackground: 'gold.700',
				subdomainColourName: 'gold',
			};
		}
		switch (subdomain) {
			case 'college':
				return {
					subdomainBackground: 'aqua.800',
					subdomainColourName: 'aqua',
				};
			case 'events':
				return {
					subdomainBackground: 'gold.700',
					subdomainColourName: 'gold',
				};
			case 'garden':
				return {
					subdomainBackground: 'pine.700',
					subdomainColourName: 'pine',
				};
			default:
				return {
					subdomainBackground: 'maroon.500',
					subdomainColourName: 'maroon',
				};
		}
	};

	const heroData = {
		subtitle: '',
		title: '',
		background: '',
		titleCategory: '',

		heroText: {
			heading: '404 - Page not found',
			supportingText: '',
			variant: 'light',
		},

		fullWidth: true,
		backgroundType: 'text',
	};
	const richTextData = {
		html: {
			__html:
				"<h3>We sincerely apologize.</h3>\n<p>We can't find the page you're looking for. Here are some helpful links to help you on your way:</p>",
		},
		buttons: [
			{
				style: 'solid',
				colorScheme: subdomainColorScheme(subdomain).subdomainColourName,
				url: process.env.NEXT_PUBLIC_APP_URL,
				target: '',
				title: 'harlaxton home',
			},
			{
				style: 'outline',
				colorScheme: subdomainColorScheme(subdomain).subdomainColourName,
				url: `${process.env.NEXT_PUBLIC_APP_URL}/contact`,
				target: '',
				title: 'contact us',
			},
		],
	};

	return (
		<Layout
			seo={null}
			navBar={navBar}
			footer={footer}
			subdomain={subdomain}
			currentUrl={'/404'}>
			<Box>
				<HeroBanner
					fullWidth={true}
					backgroundColor={subdomainColorScheme(subdomain).subdomainBackground}
					backgroundType={'text'}
					title={heroData.heroText.heading}
					subdomain={subdomain}
				/>
				<Container maxW='container.xl' mx='auto'>
					<Box pt={{base: '10', md: '12'}} pb={{base: '16', md: '20'}}>
						<Box w={{base: '100%', md: 'auto'}}>
							<RichTextCta
								html={richTextData.html}
								container={false}
								columnOffset={0}
								buttons={richTextData.buttons}
							/>
						</Box>
					</Box>
				</Container>
			</Box>
		</Layout>
	);
}

export async function getStaticProps({params, preview, req, previewData}) {
	const subdomain = params?.site || '';

	let footer = await getFooter(subdomain);
	footer = mappingFooter(footer);

	// Menus
	const globalMainMenu = await getGlobalMainMenu('');
	const mainMenu = await getMainMenu('');
	const parentSiteMenu = await getParentSiteMainMenu('');

	let navBar: any = [];

	if (mainMenu && globalMainMenu && parentSiteMenu) {
		navBar = mappingNavBar({globalMainMenu, parentSiteMenu, mainMenu});
	}

	preview = false;

	return {
		props: {navBar, footer, subdomain},
		revalidate: 50,
	};
}

export default Custom404;
